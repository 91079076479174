@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
body {
  background-color: #00070a !important;
  padding-top: 72px;
}
/* You can add global styles to this file, and also import other style files */
button[aria-expanded=true] .AccordionExpandIcon {
  background-image: url(../src/assets/chevron-down.svg);
  background-size: 15px 25px;
  width: 15px;
  height: 20px;
  background-repeat: no-repeat;
  display: inline-block;
}
button[aria-expanded=false] .AccordionExpandIcon {
  background-image: url(../src/assets/chevron-right.svg);
  background-size: 15px 25px;
  width: 15px;
  height: 20px;
  background-repeat: no-repeat;
  display: inline-block;
}
.Tools {
  background-image: url(../src/assets/tools.svg);
  background-size: 13px 13px;
  width: 13px;
  height: 13px;
  background-repeat: no-repeat;
  display: inline-block;
  cursor: pointer;
}
.SignOut {
  background-image: url(../src/assets/sign-out.svg);
  background-size: 13px 13px;
  width: 13px;
  height: 13px;
  background-repeat: no-repeat;
  display: inline-block;
  cursor: pointer;
}
.Plus {
  mask-image: url(../src/assets/plus.svg);
  mask-size: 24px 24px;
  mask-repeat: no-repeat;
  width: 30px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
}
.Question {
  mask-image: url(../src/assets/question.svg);
  mask-size: 21px 21px;
  mask-repeat: no-repeat;
  width: 21px;
  height: 21px;
  display: inline-block;
}
.ToolIcon .tooltip-inner {
  width: 140px !important;
}
.Dark {
  background-color: #00070a;
}
.Light {
  background-color: #9eb9b3;
}
.TrashCan {
  mask-image: url(../src/assets/trashcan.svg);
  mask-size: 24px 24px;
  mask-repeat: no-repeat;
  width: 30px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
}
.MoveIcon {
  mask-image: url(../src/assets/move.svg);
  mask-size: 24px 24px;
  mask-repeat: no-repeat;
  width: 30px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
}
.DownloadIcon {
  mask-image: url(../src/assets/cloud-download.svg);
  mask-size: 24px 24px;
  mask-repeat: no-repeat;
  width: 30px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
}
.CopyIcon {
  mask-image: url(../src/assets/copy.svg);
  mask-size: 24px 24px;
  mask-repeat: no-repeat;
  width: 30px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
}
.CutIcon {
  mask-image: url(../src/assets/scissors.svg);
  mask-size: 24px 24px;
  mask-repeat: no-repeat;
  width: 30px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
}
.PasteIcon {
  mask-image: url(../src/assets/paste.svg);
  mask-size: 24px 24px;
  mask-repeat: no-repeat;
  width: 30px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
}
.EditIcon {
  mask-image: url(../src/assets/edit.svg);
  mask-size: 24px 24px;
  mask-repeat: no-repeat;
  width: 30px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
}
.PencilIcon {
  mask-image: url(../src/assets/pencil.svg);
  mask-size: 24px 24px;
  mask-repeat: no-repeat;
  width: 30px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
}
.tooltip > div {
  animation-name: delayedFadeIn;
  animation-duration: 1s;
  /* Adjust this duration */
}
@keyframes delayedFadeIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  /* Set this to 99% for no fade-in. */
  100% {
    opacity: 1;
  }
}
div.card-header {
  padding: 0;
}
.mymodal .modal-dialog {
  max-width: 100%;
  margin-top: 0px;
  height: calc(100% - 72px);
  margin: auto;
  overflow-y: initial !important;
}
.mymodal .modal-content {
  color: white;
  background-color: black;
  top: 72px;
  height: 100%;
  border: 1px solid #000000;
}
.mymodal .modal-header {
  height: 90px;
  max-height: 60px;
}
.mymodal .modal-body {
  padding: 0px;
  overflow-y: auto;
}
.card {
  background-color: #597884 !important;
}
body .bg-dark,
.card-header {
  background-color: #597884 !important;
}
body .card-body {
  background-color: #294552;
}
.fixed-top {
  z-index: 1000 !important;
}
.close {
  color: #FFF !important;
  opacity: 1;
}
.axia-black {
  color: #9eb9b3;
  background-color: #00070a;
}
.bg-color-palette-1 {
  background-color: #00070a !important;
}
.bg-color-palette-2 {
  background-color: #294552 !important;
}
.bg-color-palette-3 {
  background-color: #597884 !important;
}
.bg-color-palette-4 {
  background-color: #acc4ce !important;
}
.bg-color-palette-5 {
  background-color: #9eb9b3 !important;
}
.color-palette-1 {
  color: #00070a !important;
}
.color-palette-2 {
  color: #294552 !important;
}
.color-palette-3 {
  color: #597884 !important;
}
.color-palette-4 {
  color: #acc4ce !important;
}
.color-palette-5 {
  color: #9eb9b3 !important;
}
.border-color-palette-1 {
  border: 1px solid #00070a !important;
}
.border-color-palette-2 {
  border: 1px solid #294552 !important;
}
.border-color-palette-3 {
  border: 1px solid #597884 !important;
}
.border-color-palette-4 {
  border: 1px solid #acc4ce !important;
}
.border-color-palette-5 {
  border: 1px solid #9eb9b3 !important;
}
